<template>
  <Bookings
    displayedCard="closed"
  >
    <BookingClosedCard />
  </Bookings>
</template>

<script>
import Bookings from '@/views/Bookings.vue'
import BookingClosedCard from '@/components/Bookings/BookingClosedCard.vue'

export default {
  name: 'BookingsClosed',
  components: {
    Bookings,
    BookingClosedCard
  },
  created () {
    if (!this.loggedUser.getBusiness.isBookingCreator) {
      this.$router.push({ name: 'community' })
    }
  },
  computed: {
    loggedUser () {
      return this.$store.getters.loggedUser
    }
  }
}
</script>
